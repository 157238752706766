
(function($) {
    
    $.fn.edtModal = function( options ) {
        var self = this;

        var modalId = 'edtModal-' + Math.floor((Math.random() * 1000000) + 1);

        var settings = $.extend({
            cookieDay: 7,
            imageUrl: null,
            link: null,
            mobileImageUrl: null,
            isMobile: false,
        }, options );
        
        var modalImageUrl = settings.imageUrl;
        if (isMobile() && settings.mobileImageUrl) modalImageUrl = settings.mobileImageUrl;

        var html = '<div class="edtModal modal fade '+modalId+'" id="'+modalId+'" tabindex="-1" role="dialog">'+
            '<div class="modal-dialog" role="document">'+
                '<div class="modal-content">'+
                    '<div class="modal-header">'+
                        '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
                    '</div>'+
                    '<div class="modal-body">'+
                        '<a href="'+settings.link+'">'+
                            '<img src="'+modalImageUrl+'" />'+
                        '</a>'+
                    '</div>'+
                '</div>'+
            '</div>'+
        '</div>';

        // cookie https://stackoverflow.com/questions/1458724/how-do-i-set-unset-cookie-with-jquery
        function createCookie(name, value, days) {
            var expires;
        
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toGMTString();
            } else {
                expires = "";
            }
            document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
        }

        function readCookie(name) {
            var nameEQ = encodeURIComponent(name) + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
            return null;
        }

        function isMobile() {
            return /iPhone|iPad|iPod|Android|IEMobile|Opera Mini|BlackBerry|Windows Phone|webOS/i.test(navigator.userAgent);
        }

        if (isMobile() && settings.isMobile == false) return this;

        if (readCookie('edtModal') != "1") {
            self.append( html );

            $('#'+modalId).modal('show');

            createCookie('edtModal', "1", settings.cookieDay);
        }
      
        return this;
    };
  
})(jQuery);
